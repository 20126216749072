# CoffeeScript
# The following are implementations of JavaScript functionality that is not included in IE 10 it seems

getQueryParamAppFile = (paramName) ->
  queryString = window.location.search
  urlParams = new URLSearchParams(queryString)
  paramValue = urlParams.get(paramName)
  return paramValue

# Function to get translation based on the current language or default to 'en'
getTranslationAppFile = (keyword) ->
  # Get 'lang' from the query string
  lang = getQueryParamAppFile('lang')

  # Set default language to 'en' if 'lang' is not present or not supported
  supportedLanguages = ['en', 'fr']
  if not lang? or not (lang in supportedLanguages)
    lang = 'en'

  # Find the keyword in the localization data and get the translation
  result = window.localization.filter (item) ->
    item.keyword == keyword and item[lang]?

  if result.length > 0
    return result[0][lang]
  else
    return "Keyword not found"

if !Array::indexOf

  Array::indexOf = (searchElement) ->
    'use strict'
    if this == null
      throw new TypeError
    t = Object(this)
    len = t.length >>> 0
    if len == 0
      return -1
    n = 0
    if arguments.length > 1
      n = Number(arguments[1])
      if n != n
# shortcut for verifying if it's NaN
        n = 0
      else if n != 0 and n != Infinity and n != -Infinity
        n = (n > 0 or -1) * Math.floor(Math.abs(n))
    if n >= len
      return -1
    k = if n >= 0 then n else Math.max(len - Math.abs(n), 0)
    while k < len
      if k of t and t[k] == searchElement
        return k
      k++
    -1

###
  IE Doesn't have a .startsWith either?
###

if !String::startsWith

  String::startsWith = (str) ->
    @lastIndexOf(str, 0) == 0

# IE < 9 doesn't have a trim() for strings
if !String::trim

  String::trim = ->
    @replace /^\s+|\s+$/g, ''

window.verifyConfirm = (link) ->
  if link.data 'confirm'
    return confirm link.data 'confirm'
  else
    return true

window.createForm = (link) ->
  form = $('<form>',
    method: 'POST'
    action: link.attr('href'))
  token = $('<input>',
    type: 'hidden'
    name: '_token'
    value: link.data('csrf'))
  if (link.data('method').toUpperCase() != 'POST')
    hiddenInput = $('<input>',
      name: '_method'
      type: 'hidden'
      value: link.data('method'))
  form.append(token, hiddenInput).appendTo 'body'
  return form

window.experiences = [];

$(document).ready ->
  methodLinks = $('a[data-method]')

  methodLinks.on 'click', (e) ->
    e.preventDefault()

    link       = $(this)
    httpMethod = link.data('method').toUpperCase()
    form       = undefined

    if $.inArray(httpMethod, ['PUT', 'PATCH', 'DELETE', 'POST']) == -1
      return

    form = window.createForm(link)

    if window.verifyConfirm(link)
      form.submit()

  all_records_selected = false

  $('#toggle-selections').on 'click', (ev) =>
    ev.preventDefault()

    if all_records_selected
      all_records_selected = false
      $('.record-control-row').each (index, input) ->
        $(input).prop 'checked', false
    else
      all_records_selected = true
      $('.record-control-row').each (index, input) ->
        $(input).prop 'checked', true

  display_status_for = (status, start, end) =>
    status_display = ''

    switch status
      when 'approved'
        status_display = "#{start} - #{end}"
      when 'cancelled_less_than_24_hours_notice' then status_display = 'Cancelled (-24)'
      when 'cancelled_24_48_hours_notice' then status_display = 'Cancelled (24 48)'
      when 'cancelled_48_plus_hours_notice' then status_display = 'Cancelled (48+)'
      when 'scheduling_error' then status_display = 'We Cancelled'
      when 'no_show' then status_display = 'No Show'
      when 'waitlist' then status_display = 'Action Required'
      else
        status_display = "#{start_display} - #{end_display}"
    status_display


  $('.status-change-init').on 'click', (ev) =>
    ev.preventDefault()

    if $('.record-control-row:checked').length == 0
      alert(getTranslationAppFile('select_record_message'))
      return

    self         = $(this)
    modal_window = $('#status-change-dialog').html()

    createModalContent '600px', '565px', modal_window, (ev) =>
      jQuery_1_11_1('#mass-start-time').timepicker({timeFormat: 'g:ia', step: 15})
      jQuery_1_11_1('#mass-end-time').timepicker({timeFormat: 'g:ia', step: 15})

      $('#mass-update').on 'click', (ev) =>
        self            = $(this)
        start_time      = jQuery_1_11_1('#mass-start-time').val()
        end_time        = jQuery_1_11_1('#mass-end-time').val()
        new_status      = $('#mass-update-status').val()
        shift_selection = $('input.status-change-shift:checked', '#status-control')
        if shift_selection.val() is undefined
          $('#shift-selection-error').show()
          return false
        occurs_on       = $(shift_selection).data('occurs-on')
        type            = $(shift_selection).data('type')
        no_shift_record = false

        # Declare moment values so we can test that start occurs before end
        m_start_time    = moment(start_time, 'h:mma')
        m_end_time      = moment(end_time, 'h:mma')
        start_is_before = false

        if start_time and end_time
          start_is_before = true if m_start_time.isBefore(m_end_time)
        else
          start_is_before = true

        if start_is_before
          $('.record-control-row:checked').each (index, record_selection) =>
            row         = $(record_selection).closest('.user-row')[0]
            cell        = $(row).find(".column-#{type}[data-occurs-on=#{occurs_on}]")[0]
            link        = $(cell).find('.time-entry')[0]
            daily_hours = $(cell).find('.daily-hours')[0]

            start_display  = moment($(cell).attr('data-begins'), 'HH:mm').format('h:mm') if $(cell).attr('data-begins')
            end_display    = moment($(cell).attr('data-ends'), 'HH:mm').format('h:mm')   if $(cell).attr('data-ends')
            start_data     = $(cell).attr('data-begins')
            end_data       = $(cell).attr('data-ends')
            status_display = ''

            if $(cell).attr 'data-usid'
              if start_time
                start         = moment(start_time.trim(), 'h:mma')
                start_data    = start.format('HH:mm')
                start_display = start.format('h:mm')

                $(cell).attr 'data-begins-new', start_data

              if end_time
                end         = moment(end_time.trim(), 'h:mma')
                end_data    = end.format('HH:mm')
                end_display = end.format('h:mm')

                $(cell).attr 'data-ends-new', end_data

              status_not_approved = false

              if new_status
                $(cell).attr 'data-status-new', new_status.trim()
                status_display = display_status_for(new_status.trim(), start_display, end_display)
                status_not_approved = true if new_status isnt 'approved'
              else
                current_status = $(cell).attr 'data-status'
                status_display = display_status_for(current_status, start_display, end_display)
                status_not_approved = true if current_status isnt 'approved'
                $(cell).attr 'data-status-new', current_status

              summary = moment.duration(moment(end_data, 'HH:mm').diff(moment(start_data, 'HH:mm')))
              hoursSpent = summary.asHours().toFixed(2)
              hoursSpent = hoursSpent - 0.5 if hoursSpent > 5
              hoursSpent = '' if status_not_approved

              $(link).text(status_display)
              $(daily_hours).text(hoursSpent)
              setTotalWork($(cell), calculateTotalWork($(cell)))
            else
              no_shift_record = true

            $('#close-modal').click()
        else
          $('#time-range-error').show()

        alert getTranslationAppFile('associted_shift_message') if no_shift_record
      return

  # OnChange event for filtering by year

  $('#year-filter').on 'change', (e) ->
    filter    = $('#year-filter')
    year      = filter.val()
    column    = filter.data('column')
    direction = filter.data('direction')
    route     = filter.data('route')

    window.location.href = route + '/' + column + '/' + direction + '/' + year

  $('#payroll-period-selection').on 'change', (e) ->
    filter = $('#payroll-period-selection')
    period = filter.val()
    route  = filter.data('route')
    window.location.href = route + '/' + period

  # Control Menu and Sub Menu

  $('.menu-main > .expandable > a').on('click', (ev) ->
    ev.preventDefault()
    self       = $(this)
    statusIcon = self.find('.fa')

    if self.hasClass('menu-expanded')
      self.next('.wrap-menu-sub').children('.menu-sub').hide()
      self.find('.fa-minus').removeClass('fa-minus').addClass('fa-plus')
      self.removeClass('menu-expanded')
    else
      $('.menu-sub').hide()
      $('.fa-minus').removeClass('fa-minus').addClass('fa-plus')
      $('.menu-expanded').removeClass('menu-expanded')
      self.addClass('menu-expanded')
      self.next('.wrap-menu-sub').children('.menu-sub').show()
      self.find('.fa-plus').removeClass('fa-plus').addClass('fa-minus')

    return
  )


  $('.menu-sub > .expandable > a').on('click', (ev) ->
    ev.preventDefault()
    self       = $(this)
    statusIcon = self.find('.fa')

    if self.hasClass('menu-expanded')
      self.next('.wrap-menu-sub').children('.menu-sub').hide()
      self.find('.fa-minus').removeClass('fa-minus').addClass('fa-plus')
      self.removeClass('menu-expanded')
    else
      self.addClass('menu-expanded')
      self.next('.wrap-menu-sub').children('.menu-sub').show()
      self.find('.fa-plus').removeClass('fa-plus').addClass('fa-minus')

    return
  )

  # Expand view/additional options

  $('.expand').on('click', (ev) ->
    ev.preventDefault()
    self = $(this)
    if self.hasClass('expand-permissions')
      $('.list-permissions').slideToggle(500)
      $('.form-group .col-md-4').matchHeight()
      icon = self.find('.fa')
      if icon.hasClass('fa-arrow-circle-o-down')
        icon.removeClass('fa-arrow-circle-o-down').addClass('fa-arrow-circle-o-up')
      else if icon.hasClass('fa-arrow-circle-o-up')
        icon.removeClass('fa-arrow-circle-o-up').addClass('fa-arrow-circle-o-down')
  )

  # Table Scrollable

  $('.navigate .previous').on('click', (ev) ->
    ev.preventDefault()
    self       = $(this)
    next       = $('.navigate .next')
    scrollable = $('.scrollable')
    left       = scrollable.css('left')
    dayWidth   = $('.date-container').outerWidth()
    if left is 'auto'
      left = '0px'
    if left isnt '0px'
      left = parseInt(left)
      left += dayWidth
      scrollable.css({'left': left + 'px'})
      next.removeClass('disabled')
    if left is '0px'
      self.addClass('disabled')
  )

  $('.navigate .next').on('click', (ev) ->
    ev.preventDefault()
    self       = $(this)
    previous   = $('.navigate .previous')
    scrollable = $('.scrollable')
    scrollWrap = $('.scroll-wrap')
    left       = scrollable.css('left')
    dayWidth   = $('.date-container').outerWidth()
    if scrollWrap.hasClass 'wide'
      return
    else
      limit = dayWidth * 3
    limit = '-' + limit + 'px'
    if left isnt limit
      if left is 'auto'
        left = '0px'
      left = parseInt(left)
      left -= dayWidth
      scrollable.css({'left': left + 'px'})
      previous.removeClass('disabled')
    if left is limit
      self.addClass('disabled')
  )

  # Synchronize scrollbars between the table header and rows

  $('.main-table-header').on('scroll', (e) ->
    scrollAmount = $(this).scrollLeft()
    $('.main-table-body').scrollLeft( scrollAmount )
  )

  $('.main-table-body').on('scroll', (e) ->
    scrollAmount = $(this).scrollLeft();
    $('.main-table-header').scrollLeft( scrollAmount );
  )

  # Hide and Display Tier Scores and Release Columns

  $('.hide-columns').on('click', (ev) ->
    ev.preventDefault()
    self = $(this)
    actions    = $('.management-table .actions')
    navigate   = $('.management-table .navigate')
    scrollWrap = $('.scroll-wrap')
    scrollable = $('.scrollable')
    $('.column-scores, .column-release').toggle()
    if not actions.hasClass 'narrow'
      actions.addClass 'narrow'
      self.text('Show')
      scrollable.css('left': 0)
    else
      actions.removeClass 'narrow'
      self.text('Hide')
    if not navigate.hasClass 'wide'
      navigate.addClass 'wide'
    else
      navigate.removeClass 'wide'
      navigate.children('.next').removeClass('disabled')
      navigate.children('.previous').removeClass('disabled')
    if not scrollWrap.hasClass 'wide'
      scrollWrap.addClass 'wide'
    else
      scrollWrap.removeClass 'wide'
  )

  # Toggle Summary

  $('.toggle-summary').on('click', (ev) ->
    ev.preventDefault()
    $('.daily-hours, .night-hours').toggle()
  )

  # Edit Column Days and Column Nights

  $('.time-entry').each(()->
    self = $(this)
    if self.text() is ''
      self.text('Edit')
  )

  # User Manager
  $('#employee_status').on('change', (ev) ->
    self = $(this)
    if self.val() is '3'
      $('.termination-details').show()
    else
      $('.termination-details').fadeOut()
  )

  # Reduced Hours Request
  $('#leave_start').on('change', (ev) ->
    self = $(this)
    start = self.val()
    $('#leave_end').attr('min', start)
  )
  $('#leave_end').on('change', (ev) ->
    self = $(this)
    start = self.val()
    $('#leave_start').attr('max', start)
  )


  # Modal Window

  modal = (() ->
    method       = {}
    overlay      = $('#overlay')
    modalWindow  = $('#modal-window')
    modalContent = $('#modal-content')

    method.center = () ->
      top = Math.max($(window).height() - modalWindow.outerHeight(), 0) / 2
      left = Math.max($(window).width() - modalWindow.outerWidth(), 0) / 2
      modalWindow.css({
        top: top + $(window).scrollTop(),
        left: left + $(window).scrollLeft()
      })

    method.open = (settings) ->
      modalContent.append(settings.content)

      modalWindow.css(
        width: settings.width or 'auto'
        height: settings.height or 'auto'
      )

      method.center()
      $(window).bind('resize.modal', method.center)

      modalWindow.show()
      overlay.show()

    method.close = () ->
      modalWindow.hide()
      overlay.hide()
      modalContent.empty()
      $(window).unbind('resize.modal')

    $('#close-modal').on('click', (ev) ->
      ev.preventDefault()
      method.close()
    )

    overlay.on('click', (ev) ->
      method.close()
    )

    return method
  )()

  # Modal window creation with settings

  createModalContent = (width, height, content, callback) ->
    settings = {}
    settings.width   = width
    settings.height  = height
    settings.content = content
    modal.open(settings)
    if callback isnt 'undefined' and typeof callback is 'function'
      callback()

  showUserModel = (userId) =>
    $.ajax "/admin/user/model/" + userId,
      type: 'GET',
      dataType: "html",
      success: (data) =>
        createModalContent 500, 400, $('#user-info-modal').html(), =>
          $('#user-info').html(data);
        return

  updateSummary = (shift) ->
    if typeof shift.data('begins-new') isnt 'undefined'
      start = shift.attr 'data-begins-new'
    else
      start = shift.data 'begins'
    if typeof shift.data('ends-new') isnt 'undefined'
      end = shift.attr 'data-ends-new'
    else
      end = shift.data 'ends'

    summary = moment.duration(moment(end, 'HH:mm').diff(moment(start, 'HH:mm')))
    hoursSpent = summary.asHours().toFixed(2)
    # RI - Reduce the hours spent by 0.5 hours to account for unpaid break if
    # the shift length is over 5 hours (but not equal to)
    hoursSpent = hoursSpent - 0.5 if hoursSpent > 5
    shift.children('.daily-hours').text(hoursSpent)

  calculateTotalWork = (el) ->
    totalWork = 0
    el.parents('.date-container').parent('tr').find('.date-container').each((index) ->
      self = $ this
      self.find('.daily-hours').each((index) ->
        dailyHours = $ this
        if dailyHours.text() isnt ''
          totalWork += parseFloat(parseFloat(dailyHours.text()).toFixed(2))
      )
    )
    return totalWork

  setTotalWork = (el, totalWork) ->
    empScheduleCol = el.parents('.emp-schedule').next('.total-work')
    empScheduleCol.text(totalWork.toFixed(2))
    if totalWork < 44
      empScheduleCol.removeClass('over-hours').removeClass('warning-hours').addClass('under-hours')
    if totalWork > 44
      empScheduleCol.removeClass('under-hours').removeClass('warning-hours').addClass('over-hours')
    if totalWork == 44
      empScheduleCol.removeClass('under-hours').removeClass('over-hours').addClass('warning-hours')

  $('A.open-user-popup').on 'click', (ev) ->
    ev.preventDefault()
    self = $(this)
    showUserModel self.data 'userId'

  # Time entry modal window
  editedSchedule = false
  $('.time-entry').on('click', (ev) ->
    ev.preventDefault()
    self = $(this)

    statusContent = $('#status-content').html()
    selfParent    = self.parent()
    dataUSID      = selfParent.data 'usid'

    # Retrieve the start and end times from the time entry
    if typeof selfParent.data('begins-new') isnt 'undefined'
      startTime = selfParent.attr 'data-begins-new'
    else if typeof selfParent.data('begins') isnt 'undefined'
      startTime = selfParent.data 'begins'
    else
      startTime = null

    if typeof selfParent.data('ends-new') isnt 'undefined'
      endTime = selfParent.attr 'data-ends-new'
    else if typeof selfParent.data('ends') isnt 'undefined'
      endTime = selfParent.data 'ends'
    else
      endTime = null

    # Retrieve the current status and the detailed notes
    if typeof selfParent.data('status-new') isnt 'undefined'
      status = selfParent.attr 'data-status-new'
    else if typeof selfParent.data('status') isnt 'undefined'
      status = selfParent.data 'status'
    else
      status = null

    if typeof selfParent.data('notes-new') isnt 'undefined'
      notes = selfParent.attr 'data-notes-new'
    else if typeof selfParent.data('notes') isnt 'undefined'
      notes = selfParent.data 'notes'
    else
      notes = null

    createModalContent('600px', '450px', statusContent, (ev) ->
      formStatus = $('#form-status')
      formStatus.attr('data-entry-id', dataUSID)

      # Set start time and end time in the fields formatting
      # them with timepicker.
      #timepicker needs this jquery version
      fieldStartTime = jQuery_1_11_1('#start-time')
      if startTime isnt null
        fieldStartTime.val(startTime).timepicker({'timeFormat': 'g:ia', 'step': 15})
        lastStartTime = startTime
      else if lastStartTime isnt null
        fieldStartTime.val(lastStartTime).timepicker({'timeFormat': 'g:ia', 'step': 15})
      else
        fieldStartTime.val('12:00pm').timepicker({'timeFormat': 'g:ia', 'step': 15})

      #timepicker needs this jquery version
      fieldEndTime = jQuery_1_11_1('#end-time')
      if endTime isnt null
        fieldEndTime.val(endTime).timepicker({'timeFormat': 'g:ia', 'step': 15})
        lastEndTime = endTime
      else if lastEndTime isnt null
        fieldEndTime.val(lastEndTime).timepicker({'timeFormat': 'g:ia', 'step': 15})
      else
        fieldEndTime.val('12:00pm').timepicker({'timeFormat': 'g:ia', 'step': 15})

      # Set status value in the current status field
      $('#current-status').val 'approved'

      # Set notes value in the current notes field
      if notes isnt null
        $('#detailed-notes').val notes
      else
        $('#detailed-notes').val ''

      # Post Save button click actions
      $('#save-status').on('click', (ev) ->
        ev.preventDefault()
        editedSchedule = true
        setStatus = $('#current-status').val()
        # Set visual times
        if fieldStartTime.val() isnt ''
          setStartTime = moment(fieldStartTime.val(), 'h:mma').format('HH:mm')
        else
          setStartTime = ''
        # Set visual times
        if fieldEndTime.val() isnt ''
          setEndTime = moment(fieldEndTime.val(), 'h:mma').format('HH:mm')
        else
          setEndTime = ''
        # Set notes
        setNotes = $('#detailed-notes').val()

        if status is 'deleted' and setStatus is 'deleted'
          $('#close-modal').click()
          return

        warningStartTimeBef = $('.warning-start-time-before')
        warningEndTimeAfter = $('.warning-end-time-after')
        warningStartTime = $('.warning-start-time')
        warningEndTime   = $('.warning-end-time')

        # Start Time and End Time cannot be empty
        if setStartTime is '' or setEndTime is ''
          if setStartTime is ''
            warningStartTime.show()
            warningStartTimeBef.hide()
          else
            warningStartTime.hide()
          if setEndTime is ''
            warningEndTime.show()
            warningEndTimeAfter.hide()
          else
            warningEndTime.hide()
          return

        #Verify Start time before end time
        if setStartTime.toMinutes() >= setEndTime.toMinutes()
          warningStartTime.hide()
          warningStartTimeBef.show()
          warningEndTime.hide()
          warningEndTimeAfter.show()
          return
        else
          warningStartTime.hide()
          warningStartTimeBef.hide()
          warningEndTime.hide()
          warningEndTimeAfter.hide()


        # Compare the new vs old values for start time, end time, status and notes
        # If there is a difference, update the data attributes
        displayShift = () ->
          self.text(moment(setStartTime, 'HH:mm').format('h:mm') + ' - ' + moment(setEndTime, 'HH:mm').format('h:mm'))

        assignStartTime = () ->
          selfParent.attr('data-begins-new', setStartTime)

        assignEndTime = () ->
          selfParent.attr('data-ends-new', setEndTime)

        removeNewShift = () ->
          selfParent.removeAttr 'data-begins-new'
          selfParent.removeAttr 'data-ends-new'
          selfParent.removeAttr 'data-notes-new'

        resetSummary = () ->
          self.next('.daily-hours').text('')
          setTotalWork(self, calculateTotalWork(self))

        resetShift = () ->
          setStartTime = ''
          setEndTime = ''
          removeNewShift()
          resetSummary()

        createShift = () ->
          if setStartTime isnt startTime
            assignStartTime()

          if setEndTime isnt endTime
            assignEndTime()

          # Call to update summary that calculates the difference
          # between start and end times
          updateSummary(selfParent)

        if setNotes isnt notes or setNotes isnt ''
          selfParent.attr('data-notes-new', setNotes)

        if setStatus is 'cancelled_less_than_24_hours_notice'
          createShift()
          self.text(getTranslationAppFile("cancelled_24"))
          resetSummary()
        else if setStatus is 'cancelled_24_48_hours_notice'
          createShift()
          self.text(getTranslationAppFile("cancelled_24_48"))
          resetSummary()
        else if setStatus is 'cancelled_48_plus_hours_notice'
          createShift()
          self.text(getTranslationAppFile("cancelled_48_plus"))
          resetSummary()
        else if setStatus is 'scheduling_error'
          createShift()
          self.text(getTranslationAppFile("we_cancelled"))
          resetSummary()
        else if setStatus is 'no_show'
          createShift()
          self.text(getTranslationAppFile("no_show"))
          resetSummary()
        else if setStatus is 'deleted'
          resetShift()
          self.text('-')
          resetSummary()
        else if setStatus is 'waitlist'
          createShift()
          self.text(getTranslationAppFile("waitlist"))
          resetSummary()
        else
          createShift()
          displayShift()
        selfParent.attr('data-status-new', setStatus)

        setTotalWork(self, calculateTotalWork(self))
        $('#close-modal').click()
      )
    )
  )

  # Iterate through every time entry field to see which fields are modified and
  # construct a list of fields that are modified

  checkEntries = () ->
    entries = []

    $('.time-entry').each((index) ->
      self       = $(this)
      selfParent = self.parent()
      userRow    = selfParent.parent()
      if typeof selfParent.data('usid') isnt 'undefined'
        dataUSID = selfParent.data 'usid'
      else
        dataUSID = null

      if typeof selfParent.data('status-new') isnt 'undefined'
        entry     = {}
        if dataUSID isnt null
          entry.usid = dataUSID
        else

        entry.status = selfParent.attr 'data-status-new'

        if typeof selfParent.data('user-id') isnt 'undefined'
          entry.user_id = selfParent.data 'user-id'

        if typeof selfParent.data('begins-new') isnt 'undefined'
          entry.begins_at = selfParent.attr 'data-begins-new'

        if typeof selfParent.data('ends-new') isnt 'undefined'
          entry.ends_at = selfParent.attr 'data-ends-new'

        if typeof selfParent.data('notes-new') isnt 'undefined'
          if selfParent.attr('data-notes-new') isnt ''
            entry.notes = selfParent.attr 'data-notes-new'

        if typeof selfParent.data('occurs-on') isnt 'undefined'
          if selfParent.attr('data-occurs-on') isnt ''
            entry.occurs_on = selfParent.attr('data-occurs-on')

        if selfParent.hasClass 'no-shift'
          if selfParent.data('type') isnt 'undefined'
            entry.type = selfParent.attr 'data-type'

        entries.push(entry)
    )
    return entries

  # Display entries submission result after changes are saved

  displaySubmissionResult = (result, delay) ->
    if typeof delay is 'undefined'
      delay = 2000
    if result is 'success'
      $('.entries-success').fadeIn('slow').animate({'opacity': '1.0'}, delay).fadeOut('slow')
    else if result is 'danger'
      $('.entries-danger').fadeIn('slow').animate({'opacity': '1.0'}, delay).fadeOut('slow')
    else if result is 'info'
      $('.entries-info').fadeIn('slow').animate({'opacity': '1.0'}, delay).fadeOut('slow')

  # Submit the entries that are modified via AJAX

  submitEntries = (entries, callback) ->
    user_shifts =
      'user_shifts': entries
    managementTable = $('.management-table')
    updateTo = managementTable.data('update-to')
    csrf     = managementTable.data('csrf')
    $.ajax(
      'headers' : { 'csrftoken': csrf, 'X-CSRF-TOKEN': csrf }
      'method'  : 'PATCH'
      'url'     : $('.management-table').data('update-to')
      'data'    : JSON.stringify(user_shifts)
      'success' : (results) ->
        #console.log results
        response = JSON.parse(results)
        if 'success' of response
          displaySubmissionResult('success') if response.success
          displaySubmissionResult('danger') if not response.success
          if callback
            callback();
        if 'updates' of response
          for key, value of response.updates
            userRow = $('td[data-user-id="' + value.user_id + '"][data-occurs-on="' + value.occurs_on + '"][data-type="' + value.type + '"]')
            console.log userRow
            userRow.data('usid', value.usid)
      'error'   : (results) ->
        displaySubmissionResult('danger')
    )

  # Check if there is any modified entry, if there is
  # submit the entries to be saved into the database.
  $('.save-schedule').on('click', (ev) ->
    ev.preventDefault()
    checkScheduleBeforeSave()
  )

  # Check if the schedule has beed modified
  # and if there are any requirements missing
  # before triggering the save
  checkScheduleBeforeSave = (force = false) ->

    # Shifts can be maximum 45h
    shifts_overflow = false;
    $('.user-row .total-work').each( (e,v) ->
      hours = $(this).text()
      if hours > 45
        shifts_overflow = true;
    )

    if force
      saveSchedule()
      return

    if shifts_overflow
      # Create save confimation box
      q = confirm(getTranslationAppFile('employees_over_hours_warning'))
      if (q == true)
        saveSchedule()
        return
    else
      saveSchedule()
      return

  saveSchedule = (cb) ->
    user_shifts = checkEntries()

    snapshotSent = submitSnapshotSave()

    if user_shifts.length > 0
      submitEntries(user_shifts, cb)
    else if (!snapshotSent) # Only post the 'info' if snapshot wasn't sent
      displaySubmissionResult('info')

  $('.back-to-scheduling').on('click', (e) ->
    e.preventDefault()
    url = $(this).attr('href');
    user_shifts = checkEntries()
    if user_shifts.length > 0
      q = confirm(getTranslationAppFile("schedule_modified_warning"))
      if (q == true)
        saveSchedule( (ev) ->
          window.location = url;
        )
      else
        window.location = url;
    else
      window.location = url;
  )

  # Calculate total schedule time for each time shift period for each day

  calculateScheduled = () ->
    # Populate the snapshot table shifts
    snapShotShifts = (timeShift, total, dayIndex) ->
      tableShift   = '.table-shift-' + dayIndex
      shiftRow     = '.row-' + timeShift
      $(tableShift).find(shiftRow).find('.field-scheduled').val(total)

    # Get all the shift hours of each shift time period which are
    # day time, night time, and weekend
    # for each day and weekend and add them
    addShifts = (timeShift, dayIndex) ->
      total = 0
      shift = '.shift-' + dayIndex
      if timeShift is 'day' then shiftColumn = '.column-day'
      else if timeShift is 'night' then shiftColumn = '.column-night'
      else if timeShift is 'weekend' then shiftColumn = '.column-weekend'
      () ->
        $(shift + shiftColumn).find('.daily-hours').each((index) ->
          total += parseFloat $(this).text()
        )
        snapShotShifts(timeShift, total, dayIndex)

    # Iterate through every day to calculate shift time periods
    i = 0
    while i <= 6
      dayTimeShift     = 'day'
      nightTimeShift   = 'night'
      weekendShift     = 'weekend'
      addShifts(dayTimeShift, i)()
      addShifts(nightTimeShift, i)()
      addShifts(weekendShift, i)()
      i++

  # Calculate required time with target and buffer

  calculateRequired = (target, buffer) ->
    required = parseFloat(target + (target * buffer) / 100)

  # Calculate the difference with required and scheduled

  calculateDifference = (required, scheduled) ->
    difference = scheduled - required

  # Attach event handlers to recalculate required and difference based on user input

  attachHandlers = (self, timeShift, dayIndex) ->
    row        = self.find('.row-' + timeShift)
    target     = row.find('.field-target')
    buffer     = row.find('.field-buffer')
    required   = row.find('.field-required')
    difference = row.find('.field-difference')
    scheduled  = row.find('.field-scheduled')

    # Get values of target and buffer and
    # set required and difference
    setRequiredAndDifference = () ->
      targetValue = target.val()
      bufferValue = buffer.val()
      if targetValue isnt '' and bufferValue isnt ''
        targetValue     = parseFloat(targetValue)
        bufferValue     = parseFloat(bufferValue)
        requiredValue   = calculateRequired(targetValue, bufferValue)
        scheduledValue  = parseFloat(scheduled.val())
        differenceValue = calculateDifference(requiredValue, scheduledValue)
        required.val requiredValue
        difference.val differenceValue
        if differenceValue > 0
          difference.addClass('difference-positive').removeClass('difference-negative')
        else
          difference.addClass('difference-negative').removeClass('difference-positive')

    target.on('keyup', (ev) ->
      setRequiredAndDifference()
    )

    buffer.on('keyup', (ev) ->
      setRequiredAndDifference()
    )

  # Iterate through every snapshot table and calculate the difference for each
  # time shift period for each day

  calculateAllSnapshot = () ->
    i = 0
    while i <= 6
      currentTable = '.table-shift-' + i
      $(currentTable).each((index) ->
        attachHandlers($(this), 'day', i)
        attachHandlers($(this), 'night', i)
        attachHandlers($(this), 'weekend', i)
      )
      i++

  # Snapshot modal window

  $('.snapshot').on('click', (ev) ->
    ev.preventDefault()
    snapshotContent = $('#section-snapshot').html()

    createModalContent('800px', '400px', snapshotContent, () ->
      service_uri = $('#snapshot-content').data 'service-uri'
      $("#snapshot-content").scrollTop = 0
      if (snapshotFormData.snapshots == undefined || snapshotFormData.snapshots.length <= 1)
        # We don't have saved data ready so we'll pull via Ajax
        $.ajax
          url: service_uri
          type: 'GET'
          success: (response_data) =>
            result = JSON.parse(response_data)
            for day, periods of result.snapshots
              for type, period of periods
                $("#target-#{period.id}").val period.target
                $("#buffer-#{period.id}").val period.buffer
                $("#required-hours-en-#{period.id}").val period.required_hours_en
                $("#scheduled-hours-#{period.id}").val period.scheduled_hours
                $("#difference-calcd-#{period.id}").val period.difference_calcd
            recalculateSnapshotFields true, false
      else
        # We have a valid form dump of the snapshot so use that.
        writeSnapshotFormFromData(false)
        recalculateSnapshotFields true, false

      $('#save-snapshot').on 'click', (ev) =>
        ev.preventDefault()

        snapshotPayload.payload = $('#snapshot-configuration').serialize()
        snapshotPayload.csrf_token = $('#snapshot-content').data 'storage-csrf'
        snapshotPayload.storage_uri = $('#snapshot-content').data 'storage-uri'

        snapshotFormData.snapshots = $('#snapshot-configuration').serializeArray()

        $('#close-modal').click()
        $('.save-schedule').click()
    )
  )

  # Toggle studies detail in warnings report
  $('.warning-display a').on('click', (ev) ->
    ev.preventDefault()
    self       = $(this)
    statusIcon = self.find('.fa')

    if $('#demerit-' + self.data('toggle')).hasClass('warning-studies')
      self.find('.fa-plus').removeClass('fa-plus').addClass('fa-minus')
      $('#demerit-' + self.data('toggle')).removeClass('warning-studies')
    else
      $('#demerit-' + self.data('toggle')).addClass('warning-studies')
      self.find('.fa-minus').removeClass('fa-minus').addClass('fa-plus')

    return
  )

  window.calcDayTotals = (verbose) =>

    getTotal = (selector) =>
      total = 0;
      $(selector).each (index, element)=>
        if (verbose)
          console.log "adding (element, value)", element, parseFloat $(element).find('.daily-hours').text()
        val = parseFloat $(element).find('.daily-hours').text() || 0
        total += val
      return total;

    totals = {
      mon: {
        day: getTotal('.column-day.shift-0'),
        night: getTotal('.column-night.shift-0')
      },
      tue: {
        day: getTotal('.column-day.shift-1'),
        night: getTotal('.column-night.shift-1')
      },
      wed: {
        day: getTotal('.column-day.shift-2'),
        night: getTotal('.column-night.shift-2')
      },
      thu: {
        day: getTotal('.column-day.shift-3'),
        night: getTotal('.column-night.shift-3')
      },
      fri: {
        day: getTotal('.column-day.shift-4'),
        night: getTotal('.column-night.shift-4')
      },
      sat: getTotal('.column-weekend.shift-5'),
      sun: getTotal('.column-weekend.shift-6')
    }
    if (verbose)
      console.log "totals obj is", totals

    return totals;

  ###
  # TODO this doesn't to-do it's job...
  $('.field-target.form-control').on('blur', (ev) ->
    console.log('in blur')
    recalculateSnapshotFields(false,false)
  )
  ###

  # TODO Fix hackyPlanB
  setInterval () ->
    recalculateSnapshotFields false, false
  , 100

  recalculateSnapshotFields = (calcScheduled, verbose) =>
    if typeof calcScheduled is undefined
      calcScheduled = true
    if typeof verbose is undefined
      verbose = true
    if (verbose)
      console.log "Starting recalculate"

    $(".row-weekend").each (index, element) =>
      index %= 12 # this is because the form exists twice on the dom so this runs through both of them

      schedule = $(element).find(".column-scheduled" ).find("input")

      if (calcScheduled)
        schedule.val switch index
          when index = 0  then calcDayTotals(false).mon.day
          when index = 1  then calcDayTotals(false).mon.night
          when index = 2  then calcDayTotals(false).tue.day
          when index = 3  then calcDayTotals(false).tue.night
          when index = 4  then calcDayTotals(false).wed.day
          when index = 5  then calcDayTotals(false).wed.night
          when index = 6  then calcDayTotals(false).thu.day
          when index = 7  then calcDayTotals(false).thu.night
          when index = 8  then calcDayTotals(false).fri.day
          when index = 9  then calcDayTotals(false).fri.night
          when index = 10 then calcDayTotals(false).sat
          when index = 11 then calcDayTotals(false).sun
          else -1

      if (verbose)
        console.log "schedule.val() is now", schedule.val()

      target   = $(element).find(".column-target"    ).find("input")
      buffer   = $(element).find(".column-buffer"    ).find("input")
      required = $(element).find(".column-required"  ).find("input")
      diffrnce = $(element).find(".column-difference").find("input")

      if (verbose)
        console.log "Read target value of "  , target.val()
        console.log "Read buffer value of "  , buffer.val()
        console.log "Read required value of ", required.val()
        console.log "Read schedule value of ", schedule.val()
        console.log "Read diffrnce value of ", diffrnce.val()

      t_val = parseFloat if !isNumber target.val() then 0 else target.val()
      b_val = parseFloat if !isNumber buffer.val() then 0 else buffer.val()
      required.val( (t_val + t_val * b_val / 100).toFixed(2) )
      r_val = required.val()
      s_val = schedule.val()
      diffrnce.val( (s_val - r_val).toFixed(2) )
      diffrnce.css( "color", if ((s_val - r_val).toFixed(2) < 0) then "red" else "#55595c" )
      if (verbose)
        console.log "new Required value of ", r_val
        console.log "new Diffrnce value of ", diffrnce.val()

      return true

  snapshotPayload = {}

  snapshotFormData = {}
  writeSnapshotFormFromData = (verbose) =>
    if typeof verbose is undefined
      verbose = false

    if (snapshotFormData.snapshots == undefined || snapshotFormData.snapshots.length <= 1)
      if (verbose)
        console.log "Can't write blank data to the form"
      return false
    if (verbose)
      console.log "snapshotFormData", snapshotFormData
    for field in snapshotFormData.snapshots
      if (verbose)
        console.log "reading form field (name, value)", field.name, field.value

      # pull the components used to build the ID out of field.name
      myRegexp = /.*\[(.*)\]\[(.*)\]/g
      matches = myRegexp.exec(field.name)
      if (verbose)
        console.log "got 2 match groups of ([1], [2])", matches[1], matches[2]

      # build the search id from the period-id and field name
      search_id = matches[2] + "-" + matches[1]

      # fix the incorrect seperator
      search_id = search_id.replace "_", "-"
      if (verbose)
        console.log "got new ID of ", search_id
        console.log "setting value now to ", field.value
        console.log "value was previously ", $("#"+search_id).val

      # write the value out to the form.
      $("#"+search_id).val field.value


  submitSnapshotSave = (verbose) =>
    if typeof verbose is undefined
      verbose = false
    if (snapshotPayload == {})
      if (verbose)
        console.log "Can't post an empty snapshot"
      return false

    if 'storage_uri' of snapshotPayload and 'payload' of snapshotPayload
      $.ajax
        url: snapshotPayload.storage_uri
        data: snapshotPayload.payload
        type: 'PATCH'
        beforeSend: (xhr) =>
          xhr.setRequestHeader 'X-CSRF-Token', snapshotPayload.csrf_token
          xhr.setRequestHeader 'Content-Type', 'application/x-www-form-urlencoded'
        success: (response_data) =>
          result = JSON.parse(response_data)
          snapshotPayload = {}
    return true;

  # Hide and Show Schedule Filter Options

  $('.filter-display').on('click', (ev) ->
    ev.preventDefault()
    $('.filter-options').toggle()
  )

  $('.management-table').on('click', (ev) ->
    $('.filter-options').hide()
  )

  # Find out which days each user has a shift on or not

  shiftSearch = (self) ->
    days =
      'mon': no
      'tue': no
      'wed': no
      'thu': no
      'fri': no
      'sat': no
      'sun': no

    i = 0
    while i <= 6
      if i < 5
        if self.find('.column-day').hasClass("shift-#{i}") or self.find('.column-night').hasClass("shift-#{i}")
          day   = self.find("TD.column-day.shift-#{i}")
          night = self.find("TD.column-night.shift-#{i}")
          switch i
            when 0 then days['mon'] = yes if day.data('status') == 'approved' or night.data('status') == 'approved'
            when 1 then days['tue'] = yes if day.data('status') == 'approved' or night.data('status') == 'approved'
            when 2 then days['wed'] = yes if day.data('status') == 'approved' or night.data('status') == 'approved'
            when 3 then days['thu'] = yes if day.data('status') == 'approved' or night.data('status') == 'approved'
            when 4 then days['fri'] = yes if day.data('status') == 'approved' or night.data('status') == 'approved'
      else
        if self.find('.column-weekend').hasClass("shift-#{i}")
          weekend = self.find("TD.column-weekend.shift-#{i}")
          switch i
            when 5 then days['sat'] = yes if weekend.data('status') == 'approved'
            when 6 then days['sun'] = yes if weekend.data('status') == 'approved'
      i++
    return days

  # Prepare Management Table data before being queried

  getTableData = () ->
    tableData = []
    $('.management-table .user-row, #shift-assignments-grid .user-row').each((index) ->
      self = $(this)
      days = shiftSearch self
      if typeof self.data('user-id') isnt 'undefined'
        userData =
          userID      : self.data('user-id')
          firstName   : self.data('first-name')
          lastName    : self.data('last-name')
          empID       : self.find('.emp-id, .column-id').text()
          eng         : self.find('.emp-eng, .column-e').text()
          fr          : self.find('.emp-fr, .column-f').text()
          other       : self.find('.emp-other, .column-o').text().trim()
          attendance  : self.find('.attendance').text()
          quality     : self.find('.quality').text()
          performance : self.find('.performance').text()
          mon         : days['mon']
          tue         : days['tue']
          wed         : days['wed']
          thu         : days['thu']
          fri         : days['fri']
          sat         : days['sat']
          sun         : days['sun']

        tableData.push(userData)
    )
    return tableData

  # Query fields

  queryField = (element) ->
    if (element.length == 0)
      return  # skip any unmatched jquery elements.
    currentList    = filterList.getList()
    field          = element
    fieldID        = field.attr('id')
    fieldValue     = field.val().toLowerCase()
    if $(field).is('input[type=checkbox]')
      fieldValue = '' if not $(field).is(':checked')
      return fieldValue
    else
      if currentList.indexOf(fieldID) isnt -1
        return fieldValue
      else
        return ''

  queryFirstName = () ->
    queryField($('#filter-first-name'))

  queryLastName = () ->
    queryField($('#filter-last-name'))

  queryEmpID = () ->
    queryField($('#filter-id'))

  queryEng = () ->
    queryField($('#filter-eng'))

  queryFr = () ->
    queryField($('#filter-fr'))

  queryOther = () ->
    queryField($('#filter-other'))

  queryAttendance = () ->
    queryField($('#filter-attendance'))

  queryQuality = () ->
    queryField($('#filter-quality'))

  queryPerformance = () ->
    queryField($('#filter-performance'))

  queryStudyExperience = () ->
    queryField($('#filter-study-experience'))

  # Reset Management Table to display all rows again

  resetTable = () ->
    userRows = $('.management-table .user-row, #shift-assignments-grid .user-row')
    userRows.addClass('show-user').removeClass('hide-user')
    return userRows

  # Filter Management Table with Filter List data

  filterTable = () ->
    tableData = getTableData()
    filtered  = tableData
    ids = []

    firstName = queryFirstName()
    if firstName isnt ''
      filtered = filtered.filter((obj) ->
        obj.firstName = obj.firstName + ""
        name = obj.firstName.toLowerCase()
        name.startsWith(firstName)
      )

    lastName = queryLastName()
    if lastName isnt ''
      filtered = filtered.filter((obj) ->
        obj.lastName = obj.lastName + ""
        name = obj.lastName.toLowerCase()
        name.startsWith(lastName)
      )

    empID = queryEmpID()
    if empID isnt ''
      filtered = filtered.filter((obj) ->
        obj.empID.startsWith(empID)
      )

    eng = queryEng()
    if eng isnt ''
      filtered = filtered.filter((obj) ->
        obj.eng > 0
      )

    fr = queryFr()
    if fr isnt ''
      filtered = filtered.filter((obj) ->
        obj.fr > 0
      )

    if (window.angular != undefined)
      studyExperience = queryStudyExperience()
      if (studyExperience isnt '')
        filtered = filtered.filter((obj) ->
          projects = angular.element($("#shift-assigns")).scope().projects
          project  = null

          for k,v of projects
            if v.sid == studyExperience
              experiences = v.experiences

          obj.userID in experiences
        )

    other = queryOther()
    if other isnt ''
      filtered = filtered.filter((obj) ->
        # TODO: This isn't very eloquent...
        obj.other.indexOf('S') > -1 || obj.other.indexOf('M') > -1
      )

    checkRanges = (key, fn, selector) ->
      fieldValue = fn()
      if fieldValue isnt ''
        fieldRange = selector.val()
        if fieldRange is 'gt'
          filtered = filtered.filter((obj) ->
            parseFloat(obj[key]) > parseFloat(fieldValue)
          )
        if fieldRange is 'gte'
          filtered = filtered.filter((obj) ->
            parseFloat(obj[key]) >= parseFloat(fieldValue)
          )
        if fieldRange is 'eq'
          filtered = filtered.filter((obj) ->
            parseFloat(obj[key]) is parseFloat(fieldValue)
          )
        if fieldRange is 'lt'
          filtered = filtered.filter((obj) ->
            parseFloat(obj[key]) < parseFloat(fieldValue)
          )
        if fieldRange is 'lte'
          filtered = filtered.filter((obj) ->
            parseFloat(obj[key]) <= parseFloat(fieldValue)
          )

    checkRanges('attendance', queryAttendance, $('#filter-select-attendance'))
    checkRanges('quality', queryQuality, $('#filter-select-quality'))
    checkRanges('performance', queryPerformance, $('#filter-select-performance'))

    checkDays = () ->
      checkDays = []
      $('#check-days').find('input[type=checkbox]:checked').each((index) ->
        dayName = $(this).attr('id').split('-')[1]
        checkDays.push dayName
      )
      return checkDays

    selectedDays = checkDays()
    if selectedDays.length > 0
      daysLength = selectedDays.length
      i = 0
      while i < daysLength
        filtered = filtered.filter((obj) ->
          if $('#filter-working-no-working').val() == 'N'
            obj[selectedDays[i]] is no
          else
            obj[selectedDays[i]] is yes
        )
        i++

    $.each(filtered, (index, value) ->
      ids.push(value.userID)
    )

    userRows = $('.management-table .user-row, #shift-assignments-grid .user-row')
    userRows.toggleClass('hide-user', true)
    userRows.toggleClass('show-user', false)
    userRows.each((index) ->
      self = $(this)
      if typeof self.data('user-id') isnt 'undefined'
        dataID = self.data('user-id')
        if ids.indexOf(dataID) isnt -1
          self.toggleClass('show-user', true)
          self.toggleClass('hide-user', false)
    )

  # Form a FilterList class that will collect the search fields in
  # display options and will provide methods to retrieve the items and
  # reset them

  class FilterList
    constructor: ->
      @list = []
    addToList: (item) ->
      @list.push item
    getList: ->
      @list
    resetList: ->
      @list = []

  filterList = new FilterList()

  # Check if all fields are empty in display options if so
  # display every row in the management table and remove the items from
  # the filter list

  fieldsEmpty = () ->
    isEmpty = true
    $('.filter-options input').each((index) ->
      self = $(this)

      if self.attr('type') is 'text' and self.val() isnt ''
        isEmpty = false
        return false
      else
        isEmpty = true
      if self.attr('type') is 'checkbox' and self.prop('checked') isnt false
        isEmpty = false
        return false
      else
        isEmpty = true
    )

    # Display every user row in management table and reset the filter list
    if isEmpty is true
      $('.user-row').toggleClass('hide-user', false)
      $('.user-row').toggleClass('show-user', true)
      filterList.resetList()

  # Attach event handlers to listen to changes in the input fields
  # and add the fields into the filter list that will be used to query the
  # management table and show or hide user rows in it

  $('.filter-options select, .filter-options input').on('change keyup', (ev) ->
    self        = $(this)
    fieldID     = self.attr('id')
    currentList = filterList.getList()
    if currentList.indexOf(fieldID) is -1
      if self.attr('type') is 'text' and self.val() isnt ''
        filterList.addToList(fieldID)
      else if self.attr('type') is 'checkbox'# and self.prop('checked') isnt false
        filterList.addToList(fieldID)
      else if self.is('select') and self.val() isnt ''
        filterList.addToList(fieldID)
    filterTable()
    fieldsEmpty() if not $('#shift-assigns').length
  )

  $('#filter-reset').on('click', (ev) ->
    ev.preventDefault
    filterOptions = $('.filter-options')
    filterOptions.find('input:text').val('')
    filterOptions.find('input:checkbox').prop('checked', false)
    filterOptions.find('select').prop('selectedIndex', 0)
    filterList.resetList()
    resetTable()
  )

  setTableHeight = () ->
    pageHeight    = $('html').outerHeight()
    otherElements = $('.site-header').outerHeight() + $('.site-footer').outerHeight() + $('.main-table-header').outerHeight()
    tableHeight   = pageHeight - otherElements
    $('.main-table-body').css({'height': tableHeight + 'px'})

  setTableHeight()

  # Handle Permissions Select and Deselect

  $('.select-all-permissions').on('click', (ev) ->
    ev.preventDefault()
    self      = $(this)
    sanitized = self.data('sanitized')
    listClass = '.' + sanitized
    if self.hasClass('select-all')
      $('.list-permissions input:checkbox').prop('checked', true)
      self.html('None').addClass('deselect-all').removeClass('select-all')
      $('.permission-options').html('None').addClass('deselect-all').removeClass('select-all')
    else
      $('.list-permissions input:checkbox').prop('checked', false)
      self.html('All').addClass('select-all').removeClass('deselect-all')
      $('.permission-options').html('All').addClass('select-all').removeClass('deselect-all')
  )

  $('.permission-options').on('click', (ev) ->
    ev.preventDefault()
    self      = $(this)
    sanitized = self.data('sanitized')
    listClass = '.' + sanitized
    if self.hasClass('select-all')
      $(listClass).find('li').each((ev) ->
        $(this).find('input:checkbox').prop('checked', true)
      )
      self.html('None').addClass('deselect-all').removeClass('select-all')
    else
      $(listClass).find('li').each((ev) ->
        $(this).find('input:checkbox').prop('checked', false)
      )
      self.html('All').addClass('select-all').removeClass('deselect-all')
  )

  # Customer Contacts Sort Table

  $('.table-customer-contacts').tablesorter()
  $('.table-customers').tablesorter()

  # Customer Contacts Autosuggest and Filtering

  companyNameSuggestions = () ->
    sourceURL = ''
    customerContacts = $('.customers-auto-search')

    setSourceURL = (query) ->
      if customerContacts.data('auto-company') isnt 'undefined'
        sourceURL = customerContacts.data('auto-company') + query.query

    getSourceURL = () ->
      return sourceURL

    searchCustomers = $('.customers-auto-search #search-customers')
    searchCustomers.autocomplete(
      onSearchStart: (query) ->
        setSourceURL(query)
      serviceUrl: getSourceURL
      ajaxSettings:
        data: ''
      onSelect: (suggestion) ->
        $('#search-customers-hidden').val(suggestion.data)
    )

  companyNameSuggestions()

  getCustomerID = () ->
    customer_id = $('#search-customers-hidden').val()
    if customer_id is '' or customer_id is 'undefined'
      customer_id = 'all'
    return customer_id

  getKeyword = () ->
    keyword = $('#search-customer-contacts').val()

  $('#submit-customer-contacts').on('click', (ev) ->
    ev.preventDefault()
    column      = 'last_name'
    order       = 'asc'
    customer_id = getCustomerID()
    keyword     = getKeyword()
    search      = $('.customers-auto-search')
    if search.data('contacts-search') isnt 'undefined'
      route = search.data('contacts-search')
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + column + '/' + order + '/' + customer_id + '/' + keyword
  )

  $('.customer-contacts-section #search-customers').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-customer-contacts').click()
  )

  $('#search-customer-contacts').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-customer-contacts').click()
  )

  # Check on every page load if there is a hidden id which means that
  # the page is loaded after a search or it is an edit page that
  # includes company

  preloadCompany = () ->
    sourceURL  = ''
    autoSearch = $('.customers-auto-search')
    if autoSearch.data('get-company') isnt undefined
      customerGet = autoSearch.data('get-company')
    userID = $('#search-customers-hidden').val()
    if userID is '' or userID is undefined
      return

    url = customerGet + userID

    $.ajax(
      'method'  : 'GET'
      'url'     : url
      'success' : (results) ->
        jsonResult = JSON.parse(results)
        if jsonResult.hasOwnProperty 'suggestions'
          if jsonResult.suggestions[0] isnt undefined
            if jsonResult.suggestions[0].hasOwnProperty 'value'
              $('#search-customers').val(jsonResult.suggestions[0].value)
      'error'   : (results) ->
    )

  preloadCompany()

  # If search customers field is deleted, ensure that the hidden id is
  # removed too so that the users can switch back to all display

  $('#search-customers').on('keyup', (ev) ->
    self = $ this
    if self.val() is ''
      $('#search-customers-hidden').val ''
  )

  # Customers(Companies) Search Criteria

  getCustomersKeyword = () ->
    keyword = $('.customers-search #search-customers').val()

  $('#submit-customers').on('click', (ev) ->
    ev.preventDefault()
    order   = 'asc'
    keyword = getCustomersKeyword()
    search  = $ '.customers-search'
    if search.data('customers-search') isnt 'undefined'
      route = search.data 'customers-search'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + order + '/' + keyword
  )

  $('.customers-search #search-customers').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-customers').click()
  )

  # Filter Employees

  getEmpKeyword = () ->
    keyword = $('#search-employees').val()

  getEmpStatus = () ->
    status = $('#status-employees').val()

  getEmpKeyword()

  $('#submit-employees').on('click', (ev) ->
    ev.preventDefault()
    column  = 'last_name'
    order   = 'asc'
    keyword = getEmpKeyword()
    status  = getEmpStatus()
    search  = $ '.employee-search'
    if search.data('employee-search') isnt 'undefined'
      route = search.data 'employee-search'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + column + '/' + order + '/' + status + '/' + keyword
  )

  $('#search-employees').on('keyup', (ev) ->
    $('#submit-employees').click() if ev.keyCode is 13
  )

  # Set Datepicker for desktops that don't support date input type
  if !Modernizr.inputtypes.date
    $('.datepicker').datepicker(
      orientation: 'bottom'
      format: 'yyyy-mm-dd'
      autoclose: true
    )

  # Set timepicker on fields
  # timepicker needs this jquery version
  jQuery_1_11_1('.timepicker').timepicker({'timeFormat': 'g:ia', 'step': 15})

  # Employee Modal

  $('.view-employee-modal').on('click', (e)->
    employeeID = $(this).attr('data-employee-id');
    url = '/admin/user/model/'+employeeID;
    $.ajax(
      'method'  : 'GET'
      'url'     : url
      'success' : (results) ->
        createModalContent(500, 600, results)
      'error'   : (results) ->
    )
  )

  # Get Employees

  incidentEmployeeSuggestions = () ->
    sourceURL = ''
    empIncident  = $ '.employee-query'

    setSourceURL = (query) ->
      if empIncident.data('auto-employee') isnt 'undefined'
        sourceURL = empIncident.data('auto-employee') + query.query

    getSourceURL = () ->
      return sourceURL

    searchEmployees = $ '.user-name'
    searchEmployees.autocomplete(
      onSearchStart: (query) ->
        setSourceURL(query)
      serviceUrl: getSourceURL
      ajaxSettings:
        data: ''
      onSelect: (suggestion) ->
        $('.user-id').val(suggestion.data)
    )

  incidentEmployeeSuggestions()

  # Preload employee name on page load

  preloadEmployee = () ->
    sourceURL  = ''
    autoSearch = $('.employee-query')
    if autoSearch.data('get-employee') isnt undefined
      employeeGet = autoSearch.data('get-employee')
    else
      return
    userID = autoSearch.find('.user-id').val()
    if userID is '' or userID is undefined
      return

    url = employeeGet + userID

    $.ajax(
      'method'  : 'GET'
      'url'     : url
      'success' : (results) ->
        jsonResult = JSON.parse(results)
        if jsonResult.hasOwnProperty 'suggestions'
          if jsonResult.suggestions[0] isnt undefined
            if jsonResult.suggestions[0].hasOwnProperty 'value'
              autoSearch.find('.user-name').val(jsonResult.suggestions[0].value)
      'error'   : (results) ->
    )

  preloadEmployee()

  # Remove employee hidden id when the search field is empty

  $('.employee-query .user-name').on('keyup', (ev) ->
    self = $ this
    if self.val() is ''
      $('.employee-query .user-id').val ''
  )

  # Check Reduced Hours Request Status, depending on it is state
  # show or hide status notes.

  checkReducedHoursStatus = () ->
    dropdownValue = $('.leave-requests-form #status').val()
    notesField    = $('.leave-requests-form .status-notes-field')

    if dropdownValue is 'approved' or dropdownValue is 'denied'
      notesField.show()
    else
      notesField.hide()

  checkReducedHoursStatus()

  $('.leave-requests-form #status').on('change', checkReducedHoursStatus)

  # Make Employee Incidents table sortable

  $('.table-incidents').tablesorter()

  $('.table-sortable').tablesorter()

  # Set default time picker in 60 mins
  # timepicker needs this jquery version
  jQuery_1_11_1('.full-timepicker').timepicker({'timeFormat': 'g:ia', 'step': 1})

  # Get current time

  $('.current-time').on 'click', (ev) ->
    currentTime = moment().format 'hh:mma'
    $('.full-timepicker').val(currentTime)

  # Filter Projects

  $('#submit-projects-search').on('click', (ev) ->
    ev.preventDefault()

    customerID = $('#search-customers-hidden').val()
    if customerID is ''
      customerID = 'all'
    column = 'name'
    direction = 'asc'
    keyword = $('#search-projects').val()

    if $('.projects').data('projects-filter') isnt 'undefined'
      route = $('.projects').data 'projects-filter'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + customerID + '/' + column + '/' + direction + '/' + keyword
  )

  $('#search-projects').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-projects-search').click()
  )

  $('.projects #search-customers').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-projects-search').click()
  )

  # Filter Employee Incidents

  $('#submit-employee-incidents').on('click', (ev) ->
    ev.preventDefault()
    type        = $('.employee-incidents-actions #type').val()
    disposition = $('.employee-incidents-actions #disposition').val()
    empID       = $('#regarding_user_id').val()
    sortColumn  = 'occurred_on'
    direction   = 'asc'

    if $('.employee-query').data('incidents') isnt 'undefined'
      route = $('.employee-query').data 'incidents'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + sortColumn + '/' + direction + '/' + type + '/' + disposition + '/' + empID
  )

  $('#regarding_user_name').on 'keyup', (ev) ->
    $('#submit-employee-incidents').click() if ev.keyCode is 13

  # Make Payroll Periods table sortable

  $('#table-payroll-periods').tablesorter()

  # Payroll Periods dropdown filter

  $('.payroll-periods-all #period-year').on 'change', (ev) ->
    self      = $ this
    value     = self.val()
    container = $('.payroll-periods-all')
    if container.data('location') isnt undefined
      location = container.data('location')
    else
      location = null

    if location isnt null
      window.location.href = location + '/' + value

  $('#submit-vacation-reduced-hours').on('click', (ev) ->
    ev.preventDefault()
    column    = 'created_at'
    direction = 'asc'
    status    = $('.vacation-reduced #status').val()
    type      = $('.vacation-reduced #type').val()
    userID    = $('.vacation-reduced .user-id').val()

    if $('.vacation-reduced').data('vacation-reduced-hours') isnt undefined
      route = $('.vacation-reduced').data 'vacation-reduced-hours'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + column + '/' + direction + '/' + status + '/' + type + '/' + userID
  )

  $('.vacation-reduced .user-name').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-vacation-reduced-hours').click()
  )

  # Leave reports filter

  $('#submit-vacation-reduced-reports').on('click', (ev) ->
    ev.preventDefault()
    column    = 'last_name'
    direction = 'asc'
    status    = $('.vacation-reduced-reports #status').val()
    type      = $('.vacation-reduced-reports #type').val()
    userID    = $('.vacation-reduced-reports .user-id').val()
    from      = $('.vacation-reduced-reports #date_start').val()
    if from is ''
      from = 'all'
    to        = $('.vacation-reduced-reports #date_end').val()
    if to is ''
      to = 'all'

    if $('.vacation-reduced-reports').data('vacation-reduced-reports') isnt undefined
      route = $('.vacation-reduced-reports').data 'vacation-reduced-reports'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + column + '/' + direction + '/' + status + '/' + type + '/' + from + '/' + to + '/' + userID
  )

  $('.vacation-reduced-reports .user-name').on('keyup', (ev) ->
    if ev.keyCode is 13
      $('#submit-vacation-reduced-reports').click()
  )

  # Attendance Reports Filter Button
  $('#submit-attendance-reports').on('click', (ev) ->
    ev.preventDefault()
    userID = $('.attendance-reports .user-id').val()
    if userID?.length == 0
      userID = 'all'
    from   = $('.attendance-reports #date_start').val()
    if from is ''
      from = 'all'
    to     = $('.attendance-reports #date_end').val()
    if to is ''
      to = 'all'

    if $('.attendance-reports').data('attendance-reports') isnt undefined
      route = $('.attendance-reports').data 'attendance-reports'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + from + '/' + to + '/' + userID
  )
  $('.attendance-reports .user-name').on('keyup', (ev) ->
    ev.preventDefault()
    if ev.keyCode is 13
      $('#submit-attendance-reports').click()
  )

  # Quality reports filter
  $('#submit-quality-reports').on('click', (ev) ->
    ev.preventDefault()
    userID = $('.quality-reports .user-id').val()
    if userID is '-1'
      userID = 'all'
    from   = $('.quality-reports #date_start').val()
    if from is ''
      from = 'all'
    to     = $('.quality-reports #date_end').val()
    if to is ''
      to = 'all'

    if $('.quality-reports').data('quality-reports') isnt undefined
      route = $('.quality-reports').data 'quality-reports'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + userID + '/' + from + '/' + to
  )

  # Quality reports export
  $('#export-quality-reports').on('click', (ev) ->
    ev.preventDefault()
    userID = $('.quality-reports .user-id').val()
    from   = $('.quality-reports #date_start').val()
    if from is ''
      from = $('.quality-reports #date_start').prop("defaultValue")
    to     = $('.quality-reports #date_end').val()
    if to is ''
      to = $('.quality-reports #date_end').prop("defaultValue")

    if $('.quality-reports').data('export-quality-reports') isnt undefined
      route = $('.quality-reports').data 'export-quality-reports'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + userID + '/' + from + '/' + to
  )

  $('.quality-reports .user-name').on('keyup', (ev) ->
    ev.preventDefault()
    if ev.keyCode is 13
      $('#submit-quality-reports').click()
  )

  # Productivity reports filter
  $('#submit-productivity-reports').on('click', (ev) ->
    ev.preventDefault()
    userID = $('.productivity-reports .user-id').val()
    if userID == '' || userID == undefined || userID is '-1'
      userID='all'
    studyID = $('.productivity-reports #modal-study-id').val()
    if studyID == '' || studyID == undefined
      studyID='all'
    from   = $('.productivity-reports #date_start').val()
    if from is ''
      from = 'all'
    to     = $('.productivity-reports #date_end').val()
    if to is ''
      to = 'all'

    if $('.productivity-reports').data('productivity-reports') isnt undefined
      route = $('.productivity-reports').data 'productivity-reports'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + userID + '/' + studyID + '/' + from + '/' + to
  )

  # Productivity reports filter
  $('#export-productivity-reports').on('click', (ev) ->
    ev.preventDefault()
    userID = $('.productivity-reports .user-id').val()
    if userID == '' || userID == undefined || userID is '-1'
      userID='all'
    studyID = $('.productivity-reports #modal-study-id').val()
    if studyID == '' || studyID == undefined
      studyID='all'

    from   = $('.productivity-reports #date_start').val()
    if from is ''
      from = $('.productivity-reports #date_start').prop("defaultValue")
    to     = $('.productivity-reports #date_end').val()
    if to is ''
      to = $('.productivity-reports #date_end').prop("defaultValue")

    if $('.productivity-reports').data('export-productivity-reports') isnt undefined
      route = $('.productivity-reports').data 'export-productivity-reports'
    else
      route = null

    if route isnt null
      window.location.href = route + '/' + userID + '/' + studyID + '/' + from + '/' + to
  )
  $('.get-shift-information-modal').on('click', (e) ->
    selfLink = $ this
    shiftId = selfLink.data('shift-id')
    isRemoteInterviewer = parseInt(selfLink.data('remote-interviewer')) == 1;

    html = ''
    html += '<div class="shift-information-container">'
    html += '  <div class="shift-information-container-content">'
    html += '    <div class="headline">'
    # html += '      In order to ensure the health and safety of all employees, employees who have symptoms or could have been exposed to COVID-19 will not be permitted onsite at the office. Please answer the following questions.'
    html += getTranslationAppFile("health_screening_attestation")
    html += '<br><br><a href="https://www.ontario.ca/self-assessment/" target="_blank">https://www.ontario.ca/self-assessment/</a> ' + getTranslationAppFile("open_link_assessment")
#    html += '    </div>'
    html += '    <ul class="question">'
    html += '      <li>' + getTranslationAppFile("government_self_assessment_question")
    html += '      <div>'
    html += '        <div class="form-check form-check-inline">'
    html += '          <input class="form-check-input" type="radio" name="question3" id="question3yes" value="yes">'
    html += '          <label class="form-check-label" for="question3yes">'+getTranslationAppFile('self_isolation_confirmation')+'</label> <br>'
    html += '          <input class="form-check-input" type="radio" name="question3" id="question3no" value="no">'
    html += '          <label class="form-check-label" for="question3no">'+getTranslationAppFile("self_isolation_required")+'</label>'
    html += '        </div>'
    html += '      </div>'
    html += '      <li>'+getTranslationAppFile("covid_screening_attestation")+'</li>'
    html += '      <div>'
    html += '        <div class="form-check form-check-inline">'
    html += '          <input class="form-check-input" type="radio" name="question2" id="question2yes" value="yes">'
    html += '          <label class="form-check-label" for="question2yes">'+getTranslationAppFile("attestation_completed_truthfully")+'</label> <br>'
    html += '          <input class="form-check-input" type="radio" name="question2" id="question2no" value="no">'
    html += '          <label class="form-check-label" for="question2no">'+getTranslationAppFile("attestation_not_completed_truthfully")+'</label>'
    html += '        </div>'
    html += '      </div>'
    html += '      <div class="error">'
    html +=            getTranslationAppFile("answer_all_questions_prompt")
    html += '      </div>'
    html += '    </ul>'
    html += '    <div class="term-condition">'
    html +=         getTranslationAppFile("health_safety_protocol_agreement")
    html += '    </div>'
    html += '    <div class="tc">'
    html += '      <div class="form-check form-check-inline">'
    html += '          <input class="form-check-input" type="radio" name="tc" id="tc_agree" checked value="1">'
    html += '          <label class="form-check-label" for="tc">'+getTranslationAppFile("agree_health_safety_protocols")+'</label>'
    html += '      </div>'
    html += '      <div class="form-check form-check-inline">'
    html += '          <input class="form-check-input" type="radio" name="tc" id="tc_disagree" value="0">'
    html += '          <label class="form-check-label" for="tc">'+getTranslationAppFile("disagree_health_safety_protocols")+'</label>'
    html += '      </div>'
    html += '    </div>'
    html += '<div class="addtional-notes">'
    html += '<p>'+getTranslationAppFile("mask_policy_note")+'</p> '
    html += '</div>'
    html += '  <div class="text-center buttons">'
    html += '    <button type="button" class="btn btn-warning btn-submit">'+getTranslationAppFile("submit_close_buttons")+'</button>'
    html += '  </div>'
    html += '</div>'
    html += '    <div class="vaccination-status-container">'
    html += '      <div class="vaccination-status-error">'
    html +=             getTranslationAppFile("answer_required")
    html += '      </div>'
    html += '    </div>'
    html += '  </div>'


    height = 850
    if (isRemoteInterviewer)
      html = '';
      html += '<div class="shift-information-container">'
      html += '  <div class="shift-information-container-content">'
      html += '  </div>'
      html += '  <div class="text-center buttons hidden">'
      html += '    <button type="button" class="btn btn-warning btn-submit">'+getTranslationAppFile("submit_close_buttons")+'</button>'
      html += '  </div>'
      html += '</div>'
      height = 550
    createModalContent(800, height, html, () ->
      $('#modal-window').css('max-height', 'calc(100vh - 125px)');
      $('.btn-no').unbind('click').click(() ->
        $('#modal-window #close-modal').trigger('click');
      )
      $('#modal-window').addClass('shift-information-modal')
      $('.btn-submit').unbind('click').click(() ->
        self = $ this;
        $('.error').hide();
#        answeredQuestion1 = $(".question1yes:checked").length + $(".question1no:checked").length == 8;
        answeredQuestion2 = $('input[name=question2]:checked', '.question').val();
        answeredQuestion3 = $('input[name=question3]:checked', '.question').val();

        # Quite stupid, but fastest
        agreedTC = $('input[name=tc]:checked').val() == '1';

        if (isRemoteInterviewer)
          answeredQuestion2 = 'yes';
          answeredQuestion3 = 'yes';
          agreedTC = true;
        if (
          answeredQuestion2 == undefined  ||
          answeredQuestion3 == undefined
        )
          # All questions are not answered
          $('.error').fadeIn('fast');
          return


        self.attr('disabled', true)
        self.find('i').addClass('fa-spin');
        tc = 'no';
        if (agreedTC)
          tc = 'yes'

        data = {
          question2: answeredQuestion2,
          question3: answeredQuestion3,
          shiftId: shiftId,
          agreedTC: tc,
        };
        if ($('input[name=tc]:checked').val() == '0' && !confirm(getTranslationAppFile("disagree_protocols_confirmation")))
          self.find('i').removeClass('fa-spin')
          self.attr('disabled', false);
          return;

        if (!agreedTC ||
          answeredQuestion2 == 'no' ||
          answeredQuestion3 == 'no'
        )
          # Do ajax to send email to HR
          $.ajax(
            'method'  : 'POST'
            'url'     : '/dashboard/schedule/unqualified-answer'
            'data'    : data
            'success' : (results) ->
              self.find('i').removeClass('fa-spin')
              if results.success
                successHtml = '';
                successHtml += '<div class="shift-information-container" style="padding-bottom: 30px;">'
                successHtml += '  <div class="shift-information-container-content">'
                successHtml += '    <div class="section1">'
                successHtml +=         getTranslationAppFile("ontario_self_assessment_instructions")
                successHtml += '       <p style="text-align:center"><img src="'+getTranslationAppFile("covid_image_src")+'" width="350px" /></p>'
                successHtml += '    </div>'
                successHtml += '  </div>'
                successHtml += '</div>'
                successHtml += '<div>';

                $('.shift-information-container-content').html(successHtml);
                $('#modal-window').css('height', '710px')
                self.hide()
              else
                self.find('i').removeClass('fa-spin')
                self.attr('disabled', false);
                $('.error').fadeIn('fast').html(results.message);
            'error'   : (results) ->
              self.find('i').removeClass('fa-spin')
              self.attr('disabled', false);
              $('.error').fadeIn('fast').html(getTranslationAppFile("unknown_error"));
          )
          return

        $.ajax(
          'method'  : 'POST'
          'url'     : '/dashboard/schedule/qualified-answer'
          'data'    : data
          'success' : (results) ->
            self.find('i').removeClass('fa-spin')
            if results.success
              $('.shift-information-container-content').html(results.data.html)
              $('#modal-window').css('height', '550px')
              self.find('i').removeClass('fa-spin')
              self.attr('disabled', false)
              self.hide()
              if (isRemoteInterviewer)
                $('.text-center.buttons').removeClass('hidden')
            else
              self.find('i').removeClass('fa-spin')
              self.attr('disabled', false);
              $('.error').fadeIn('fast').html(results.message);
          'error'   : (results) ->
            self.find('i').removeClass('fa-spin')
            self.attr('disabled', false);
            $('.error').fadeIn('fast').html(getTranslationAppFile("unknown_error"));
        )
      )
      if (isRemoteInterviewer)
        $('.btn-submit').trigger('click')
    )
  )
  if( $('#break-on-off').length )
    $('#break-on-off').on('click', (ev) =>
      ev.preventDefault()
      $.ajax(
        'method'  : 'PATCH'
        'url'     : $('#break-on-off').attr('href') + "/break_toggle"
        'success' : (results) ->
          try
            response = JSON.parse(results)
            if 'success' of response
              if response.success
                if response.status == "on"
                  if $("#break-on-off").hasClass("off")
                    $("#break-on-off").removeClass("off")
                    $("#break-on-off").addClass("on")
                if response.status == "off"
                  if $("#break-on-off").hasClass("on")
                    $("#break-on-off").removeClass("on")
                    $("#break-on-off").addClass("off")
              if not response.success
                location.reload()
          catch error
            location.reload()
        'error'   : (results) ->
          location.reload()
      )
    )

    setInterval () ->
      $.ajax(
        'method'  : 'GET'
        'url'     : $('#break-on-off').attr('href') + "/check_access"
        'success' : (results) ->
          try
            response = JSON.parse(results)
            if 'success' of response
              if response.success
                if response.status == "close"
                  location.reload()
              if not response.success
                location.reload()
          catch error
            location.reload()
        'error'   : (results) ->
          location.reload()
      )
    , 900000


  # Make Statutory Holidays table sortable

  $('.table-statutory-holidays').tablesorter()

  # Make Projects table sortable

  $('.table-projects').tablesorter()

  # Make Vacation & Reduced Hours Requests table sortable

  $('.table-vacation-reduced-hours').tablesorter()

  # Make Vacation Requests table sortable

  $('.table-vacation-requests').tablesorter()

  # Make Reduced Hours Requests table sortable

  $('.table-reduced-hours-requests').tablesorter()

  # Add wysiwyg text editor support with summernote

  $('.advanced-editor').trumbowyg(
    btns:
      [
        'bold'
        'italic'
        'underline'
        'strikethrough'
        'foreColor'
        'backColor'
        'viewHTML'
        'link'
        'justifyLeft'
        'justifyRight'
        'justifyCenter'
        'justifyFull'
        'unorderedList'
        'orderedList'
      ]
  )

  $('.basic-editor').trumbowyg(
    btns:
      [

      ]
  )

  # Cell, dropdown navigation

  $('.cell-dropdown').focus (ev) ->
    self    = $ this
    cellNav = $ '.cell-nav'
    cellNav.removeClass 'current-cell'
    self.parent('.cell-nav').toggleClass('current-cell')

  timezone_noticed = getCookie('timezone_noticed')
  if (timezone_noticed != '1')
    $('.timezone-notice').show()
    $('.notice-close').click (ev) ->
      setCookie('timezone_noticed', '1', 90);
      $('.timezone-notice').hide()
  return

window.validateNumber = (ev, el) ->
  if $.inArray(ev.keyCode, [46, 8, 9, 27, 13, 110, 190]) != -1 ||
       # Allow: Ctrl+A
      (ev.keyCode == 65 && ev.ctrlKey == true) ||
       # Allow: Ctrl+C
      (ev.keyCode == 67 && ev.ctrlKey == true) ||
       # Allow: Ctrl+X
      (ev.keyCode == 88 && ev.ctrlKey == true) ||
       # Allow: home, end, left, right
      (ev.keyCode >= 35 && ev.keyCode <= 39)
           # let it happen, don't do anything
           return;

  # Ensure that it is a number and stop the keypress
  if (ev.shiftKey || (ev.keyCode < 48 || ev.keyCode > 57)) && (ev.keyCode < 96 || ev.keyCode > 105)
      ev.preventDefault();
      return false

window.isNumber = (val) ->
  if isNaN val
    return false
  if val.length == 0
    return false
  return true

window.validateNumberB = (el) ->
  self = $(el)
  if !isNumber self.val()
    self.val '0.00'

window.setCookie = (cname, cvalue, exdays) ->
  d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

window.getCookie = (cname) ->
  name = cname + "="
  decodedCookie = decodeURIComponent(document.cookie);
  ca = decodedCookie.split(';')
  for c in ca
    while (c.charAt(0) == ' ')
      c = c.substring(1)
    if (c.indexOf(name) == 0)
      return c.substring(name.length, c.length)

  return "";
