window.localization = [
  # app.coffee file
  # app.coffee file
  # app.coffee file
  # app.coffee file
  {
    keyword: "no",
    en: "no",
    fr: "Non"
  },
  {
    keyword: "yes",
    en: "yes"
    fr: "Oui"
  },
  {
    keyword: "select_record_message",
    en: "You must select records to perform a mass update",
    fr: "Vous devez sélectionner des enregistrements pour effectuer une mise à jour en masse."
  },
  {
    keyword: "employees_over_hours_warning",
    en: "Employees are scheduled for more than 45 hours, please adjust shift schedules. Press OK to save the timetable anyway.",
    fr: "L’horaire des employés comprend plus de 45 heures; veuillez modifier les horaires de travail. Appuyez sur OK pour enregistrer quand même l’horaire."
  },
  {
    keyword: "health_screening_attestation",
    en: "Please visit the following Government of Ontario, Health Canada regulated website to complete the mandatory health screening attestation before you arrive for your shift.",
    fr: "Veuillez vous rendre sur le site Web suivant régit par Santé Canada et le gouvernement de l’Ontario pour remplir l’attestation de santé obligatoire avant de commencer votre quart."
  },
  {
    keyword: "open_link_assessment",
    en: "(opens in new window)",
    fr: "(s’ouvre dans une nouvelle fenêtre)"
  },
  {
    keyword: "government_self_assessment_question",
    en: "Did the Government of Ontario Self Assessment inform you that you do <b>not</b> need to self isolate / stay home?",
    fr: "L’outil d’auto-évaluation du gouvernement de l’Ontario vous a-t-il dit que vous <b>n’avez pas</b> besoin de vous auto-isoler / rester à la maison?"
  },
  {
    keyword: "self_isolation_confirmation",
    en: "Yes - I can go to work (do not need to self isolate)",
    fr: "Oui - je peux venir travailler (je n’ai pas besoin de m’auto-isoler)"
  },
  {
    keyword: "self_isolation_required",
    en: "No - I must self isolate",
    fr: "Non - je dois m’auto-isoler"
  },
  {
    keyword: "covid_screening_attestation",
    en: "I hereby attest that I completed the COVID-19 screening self-assessment truthfully, in accordance with the established provincial rules and guidelines.",
    fr: "Je confirme par la présente que j’ai rempli honnêtement le questionnaire d’auto-évaluation de la COVID-19, conformément aux règles et aux lignes directrices de la province."
  },
  {
    keyword: "attestation_completed_truthfully",
    en: "Yes, my attestation was completed truthfully",
    fr: "Oui, j’ai rempli honnêtement mon formulaire d’attestation"
  },
  {
    keyword: "attestation_not_completed_truthfully",
    en: "No, my attestation was not completed truthfully",
    fr: "Oui, je n’ai pas rempli honnêtement mon formulaire d’attestation"
  },
  {
    keyword: "answer_all_questions_prompt",
    en: "Please answer all questions above!",
    fr: "Veuillez répondre à toutes les questions ci-dessus!"
  },
  {
    keyword: "health_safety_protocol_agreement",
    en: "Elemental Data Collection is committed to removing any possible risks in accordance with government regulations, best practices, and out of the care and concern for all employees, their families, and the surrounding community. In order to ensure your health and safety and the health and safety of others, you must agree to abide by all workplace health and safety protocols at all times while onsite, without exception. Please confirm below that you agree to abide by all health and safety protocols:",
    fr: "Elemental Data Collection s’engage à éliminer tous les risques possibles conformément à la réglementation gouvernementale et aux pratiques exemplaires, car le bien-être de tous les employés, de leurs familles et de la population environnante lui tient à cœur. Afin d’assurer votre santé et votre sécurité ainsi que celles des autres, lorsque vous êtes sur place, vous devez accepter de respecter en tout temps et sans exception tous les protocoles de santé et sécurité au travail. Veuillez confirmer ci-dessous que vous acceptez de respecter tous les protocoles de santé et sécurité :"
  },
  {
    keyword: "agree_health_safety_protocols",
    en: "I agree to the health and safety protocols",
    fr: "J’accepte les protocoles de santé et de sécurité"
  },
  {
    keyword: "disagree_health_safety_protocols",
    en: "I DO NOT agree to the health and safety protocols",
    fr: "JE N’ACCEPTE PAS les protocoles de santé et de sécurité"
  },
  {
    keyword: "mask_policy_note",
    en: '<b style="color:red"> Note: </b> Masks are no longer mandatory however strongly recommended and encouraged while walking through the call centre. Elemental will remain a mask friendly workplace.',
    fr: '<b style="color:red"> Note : </b> Les masques ne sont plus obligatoires, mais il vous est fortement recommandé d’en porter un quand vous circulez dans le centre d’appels. Elemental restera un milieu de travail favorable au port du masque.'
  },
  {
    keyword: "answer_required",
    en: "This answer is required!",
    fr: "Cette réponse est obligatoire!"
  },
  {
    keyword: "submit_close_buttons",
    en: '<i class="fa fa-check"></i>&nbsp;Submit</button>&nbsp;<button class="btn btn-info btn-no"><i class="fa fa-times"></i>&nbsp;Close</button>',
    fr: '<i class="fa fa-check"></i>&nbsp;Soumettre</button>&nbsp;<button class="btn btn-info btn-no"><i class="fa fa-times"></i>&nbsp;Fermer</button>'
  },
  {
    keyword: "disagree_protocols_confirmation",
    en: "Choosing the 'I DO NOT agree to the health and safety protocols.' option will trigger an email to HR department. Are you sure you want to choose this option?",
    fr: "Si vous choisissez l’option « JE N’ACCEPTE PAS les protocoles de santé et de sécurité », un courriel sera envoyé au service des RH. Voulez-vous vraiment choisir cette option?"
  },
  {
    keyword: "unknown_error",
    en: "There is unknown error. Please try later.",
    fr: "Une erreur inconnue est survenue. Veuillez réessayer plus tard."
  },
  {
    keyword: "ontario_self_assessment_instructions",
    en: '<p>If the Government of Ontario Self Assessment informed you to self isolate, select the option to “Email self-assessment result” (as shown below) on the Government of Ontario mandatory health screening site <a href="https://www.ontario.ca/self-assessment/" target="_blank">https://www.ontario.ca/self-assessment/</a> and email your failed attestation to <a href="mailto:HR@elementaldci.com">HR@elementaldci.com</a> and confirm if you have tested positive for COVID-19. Do not show up for work.</p> <p>If the Government of Ontario Self Assessment informed you that you did not need to self isolate, please email <a href="mailto:HR@elementaldci.com">HR@elementaldci.com</a> and explain you cannot complete your assessment truthfully and/or agree with the health and safety protocols. Do not show up for work.</p>',
    fr: "<p>Si l’outil d’auto-évaluation du gouvernement de l’Ontario vous a dit de vous auto-isoler, sélectionnez l’option « Envoyer le résultat de l’auto-évaluation par courriel » (comme illustré ci-dessous) sur le site de contrôle obligatoire de l’état de santé du gouvernement de l’Ontario <a href='https://www.ontario.ca/autoevaluation/' target='_blank'>https://www.ontario.ca/autoevaluation/</a>, puis envoyez l’échec de l'attestation à <a href='mailto:HR@elementaldci.com'>HR@elementaldci.com</a> et confirmez si vous avez reçu un résultat positif à un test de dépistage de la COVID-19. Ne venez pas travailler.</p> <p>Si l’outil d’auto-évaluation du gouvernement de l’Ontario vous dit que vous n’avez pas besoin de vous auto-isoler, veuillez envoyer un courriel à <a href='mailto:HR@elementaldci.com'>HR@elementaldci.com</a> et expliqué que vous ne pouvez répondre honnêtement aux questions de l’évaluation ou accepter les protocoles de santé et de sécurité. Ne venez pas travailler.</p>"
  },
  {
    keyword: "cancelled_24",
    en: "Cancelled (-24)",
    fr: "Annulation (moins de 24 heures)"
  },
  {
    keyword: "cancelled_24_48",
    en: "Cancelled (24 48)",
    fr: "Annulation (24 à 48 heures)"
  },
  {
    keyword: "cancelled_48_plus",
    en: "Cancelled (48+)",
    fr: "Annulation (plus de 48 heures)"
  },
  {
    keyword: "we_cancelled",
    en: "We Cancelled",
    fr: "Nous avons annulé"
  },
  {
    keyword: "",
    en: "No Show",
    fr: "Absent(e)"
  },
  {
    keyword: "waitlist",
    en: "Waitlist",
    fr: "Liste d’attente"
  },


  # shift_assignments.coffee file
  # shift_assignments.coffee file
  # shift_assignments.coffee file
  # shift_assignments.coffee file

  {
    keyword: "project",
    en: "Project",
    fr: "Projet"
  },
  {
    keyword: "target_hours",
    en: "Target Hrs",
    fr: "Heures cibles"
  },
  {
    keyword: "total",
    en: "Total",
    fr: "Total"
  },
  {
    keyword: "no_studies_in_period",
    en: "No Studies In Period",
    fr: "Aucune étude durant la période"
  },
  {
    keyword: "breaks_exceed_assigned_time",
    en: "A combination of one or more breaks exceeds assigned time for their study",
    fr: "Une ou plusieurs pauses dépassent le temps imparti pour l’étude."
  },
  {
    keyword: "breaks_exceed_shift_duration",
    en: "The total duration of breaks exceeds the shift duration",
    fr: "La durée totale des pauses dépasse la durée du quart du travail"
  },
  {
    keyword: "choose_break_type",
    en: "You must choose a break type",
    fr: "Vous devez choisir un type de pause"
  },
  {
    keyword: "regarding",
    en: "Regarding",
    fr: "Concernant"
  },
  {
    keyword: "changed_by",
    en: "Changed By",
    fr: "Modifié par"
  },
  {
    keyword: "covid_image_src",
    en: "/images/en-email-self-assess-v2.png",
    fr: "/images/fr-email-self-assess-v2.png"
  },
]