class @ITable
  current:  null
  i_cell:   null
  table:    null
  excluded: null

  #region Constructor & Init
  constructor: (@table, @excluded) ->
    @i_cell = new ICell()
    @

  init: ->
    if $("##{@table}").length
      $(document).on 'click', (ev) =>
        @editable_cell_click_event ev
      $(document).on 'dblclick', (ev) =>
        @handle_cell_dblclick_event ev
      $(document).on 'keydown', (ev) =>
        @handle_key_event ev
    @
  #endregion

  #region Event Handling
  handle_key_event: (ev) ->
    if @current != null
      switch ev.which
        when 27 then @disable_cell()
        when 37 then @prev_cell()
        when 39 then @next_cell()
        when 38 then @prev_row()
        when 40 then @next_row()
        else
          @i_cell.respond_to ev.which
      ev.preventDefault() unless @i_cell.defer_to_cell()

  editable_cell_click_event: (ev) ->
    target = ev.target

    if $(target).hasClass('editable')
      @activate_cell ev.target, false if @table_houses ev.target
    else if $(target).parent().hasClass('editable')
      @activate_cell $(ev.target).parent(), false if @table_houses $(ev.target).parent()
    else
      @disable_cell()

    return

  handle_cell_dblclick_event: (ev) ->
    target = ev.target

    if $(target).is('.editable, .column-bg')
      @activate_dialog()
  #endregion

  #region Navigation
  next_cell: ->
    editables = $('.editable').filter(':visible')
    
    if @current_has_editable_inputs()
      inputs = $(@current).find('.editable-input')
      active = document.activeElement;
      idx    = inputs.index active

      if (inputs.length - 1) > idx
        @i_cell.focus(inputs.eq(idx + 1))
      else
        @move_next editables
    else
      @move_next editables

  move_next: (editables) ->
    idx  = editables.index(@current)
    next = editables.eq(idx + 1)

    if next != null and (idx + 1) != editables.length
      @activate_cell next[0]

  prev_cell: ->
    editables = $('.editable').filter(':visible')

    if @current_has_editable_inputs()
      inputs = $(@current).find('.editable-input')
      active = document.activeElement;
      idx    = inputs.index active

      if (inputs.length - 1) <= idx
        if $('.training-hours-input').length > 0
          @i_cell.focus(inputs.eq(idx - 1))
        else
          @move_prev editables
      else
        @move_prev editables
    else
      @move_prev editables

  move_prev: (editables) ->
    idx  = editables.index(@current)
    prev = editables.eq(idx - 1)

    if prev != null and (idx - 1) >= 0
      @activate_cell prev[0]

  next_row: ->
    columns = @cousins()
    input   = @cousin_input()
    @move_down(columns)
    if @current_has_editable_inputs() and input != null
      @i_cell.focus $(@current).find(input)

  move_down: (columns) ->
    idx     = columns.index(@current)
    next    = columns.eq(idx + 1)

    if next != null and (idx + 1) != columns.length
      @activate_cell next[0]

  prev_row: ->
    columns = @cousins()
    input   = @cousin_input()
    @move_up(columns)
    if @current_has_editable_inputs() and input != null
      @i_cell.focus $(@current).find(input)

  move_up: (columns) ->
    idx     = columns.index(@current)
    prev    = columns.eq(idx - 1)

    if prev != null and (idx - 1) >= 0
      @activate_cell prev[0]

  #endregion

  #region Manipulation
  activate_cell: (new_cell, from_key_event = true) ->
    if @current
      @disable_cell()

    @current = new_cell

    @eyes_on_target() if from_key_event
    @i_cell.current_subject @current
    @i_cell.focus()
    $(@current).toggleClass('current-cell')

  activate_dialog: ->
    @i_cell.current_subject @current
    @i_cell.display_dialog()

  clear_current_selection: ->
    $('.current-cell').toggleClass('current-cell')
  
  enable_cell: ->
    @i_cell.current_subject @current
    @i_cell.focus()

  eyes_on_target: ->
    $(document).scrollTo(@current, 0, {offset: -60})

  disable_cell: ->
    @clear_current_selection()
    @i_cell.blur()
    @i_cell.store()
    @current = null
  #endregion

  #region Checks
  table_houses: (target) ->
    table = $(target).parents('table')[0]
    $(table).attr('id') == @table

  current_has_editable_inputs: ->
    $(@current).find('.editable-input').length > 0
  #endregion

  cousins: ->
    classes          = $(@current).attr('class').split ' '
    cousin_selector  = ''
    cousin_selector += ".#{css_class}" for css_class in classes when (css_class != 'current-cell' and (css_class not in @excluded))
    $(cousin_selector)

  cousin_input: ->
    if document.activeElement isnt 'undefined' and @current_has_editable_inputs()
      classes = $(document.activeElement).attr('class').split ' '
      cousin_input_selector  = ''
      cousin_input_selector += ".#{css_class}" for css_class in classes
      return cousin_input_selector
    else
      return null
