class @ICell
  subject:              null
  map:                  {}
  map_cur:              null
  act_as_key_authority: false

  value_differs_from_original: (value) ->
    data = $(@subject).data()
    String(value).trim().localeCompare(String(data.original).trim()) != 0

  constructor: ->
    # This map contains the callbacks specific to each cell type, allowing a single cell handler
    # to be implemented quickly in the future for any other cells
    #
    # Ensure that you detect the cell type in determine_actions and set the @map_cur to the
    # key in the map and this will detect and run the appropriate callback.
    #
    #region Map
    @map = {
      station: {
        on_stroke: (stroke) =>
          valid = [65, 66, 67, 68, 69]
          value = String.fromCharCode(stroke) if stroke in valid
          data  = $(@subject).data()

          if stroke in valid
            $(@subject).data('next', value) if @value_differs_from_original(value)
            $(@subject).find('span').html(value)
          return
        ,
        on_activate: =>
            return
        ,
        store: =>
          data       = $(@subject).data()
          row_data   = $(@subject).parent().data()

          if @next_value_exists()
            update = station: data.next
            @send row_data.us_service_uri, update, @subject
      },
      booth: {
        on_stroke: (stroke) =>
          return
        ,
        on_activate: =>
          @enable_key_authority()
          input = $(@subject).find('input')
          input.css('display', 'block')
          $(@subject).focus()
          input.focus().select()
          $(@subject).find('span').css('display', 'none')
          return
        ,
        on_deactivate: =>
          @disable_key_authority()

          input = $(@subject).find('input')
          data  = $(@subject).data()
          value = input.val()

          input.blur()
          input.css('display', 'none')

          $(@subject).find('span').css('display', 'block')
          $(@subject).data 'next', value if @value_differs_from_original(value)
          $(@subject).find('span').html(value)
          return
        ,
        store: =>
          data     = $(@subject).data()
          row_data = $(@subject).parent().data()

          if @next_value_exists()
            update = booth: data.next
            @send row_data.us_service_uri, update, @subject
      },
      break_group: {
        on_stroke: (stroke) =>
          valid = [65, 66, 67, 68, 69]
          value = String.fromCharCode(stroke) if stroke in valid

          if stroke in valid
            $(@subject).data('next', value) if @value_differs_from_original value
            $(@subject).html(value)
          return
        ,
        store: =>
          data     = $(@subject).data()
          row_data = $(@subject).parent().data()

          if @next_value_exists()
            update = break_group: data.next
            @send row_data.us_service_uri, update, @subject
        ,
        on_dblclick: () =>
          angular.element($("#shift-assigns")).scope().configure_break_groups(@subject)
      },
      attendance: {
        on_stroke: (stroke) =>
          # E = Left Early (69)
          # L = Late (76)
          # N = Not Checked In (78) [default]
          # O = On Time (79)
          valid = [69, 76, 78, 79]
          value = String.fromCharCode(stroke) if stroke in valid

          if stroke in valid
            $(@subject).data('next', value) if @value_differs_from_original value
            $(@subject).find('span').html(value)
          return
        ,
        store: =>
          data     = $(@subject).data()
          row_data = $(@subject).parent().data()
          actual   = null

          if @next_value_exists()
            switch data.next
              when 'E' then actual = 'left_early'
              when 'O' then actual = 'on_time'
              when 'N' then actual = 'not_checked_in'
              when 'L' then actual = 'late'
            update = attendance_status: actual
            @send row_data.us_service_uri, update, @subject
      },
      in_out: {
        on_stroke: (stroke) =>
          return
      }
      paid_hours: {
        on_stroke: (stroke) =>
          return
        , on_activate: =>
          @enable_key_authority()
          input = $(@subject).find('input')
          input.css('display', 'block').focus().select()
          $(@subject).find('span').css('display', 'none')
          return
        , on_deactivate: =>
          @disable_key_authority()
          input = $(@subject).find('input')
          input.blur()
          input.css('display', 'none')

          value = input.val()
          $(@subject).find('span').css('display', 'block')
          $(@subject).data.next = value
          $(@subject).find('span').html(value)
          return
      },
      assignments: {
        last_el: false,
        on_stroke: (stroke) =>
          return
        ,
        on_activate: (el = null) =>
          @enable_key_authority()
          if el == null
            input = $(@subject).find('input:first')
            input.focus().select()
            if input.hasClass('regular-hours-input')
              input.val('')
              @last_el = input
          else
            el.focus().select()
            if el.hasClass('regular-hours-input') || el.hasClass('training-hours-input')
              if el.hasClass('regular-hours-input')
                @last_el = el
                auxi = el.parent().find('.training-hours-input')
                if auxi.length > 0
                  if auxi.val().replace(' ', '') == ''
                    nval = el.parent().attr('data-original-training-hours')
                    auxi.val nval
                  else
                    el.parent().attr 'data-original-training-hours', auxi.val()
              if el.hasClass('training-hours-input')
                @last_el = el
                auxi = el.parent().find('.regular-hours-input')
                if auxi.val().replace(' ', '') == ''
                  nval = el.parent().attr('data-original-regular-hours')
                  auxi.val nval
                else
                  el.parent().attr 'data-original-regular-hours', auxi.val()
              el.val('')
        ,
        on_deactivate: =>
          @disable_key_authority()
          input = $(document.activeElement)
          if input.hasClass('regular-hours-input') || input.hasClass('training-hours-input')
            if @last_el.val().replace(' ', '') == ''
              input = @last_el
          else
            if @last_el
              input = @last_el
            else
              input = false


          if input
            if input.attr('ng-if') == '!readonly.assigns'
              if input.val().replace(' ', '') == ''
                if input.hasClass('regular-hours-input')
                  nval = input.parent().attr('data-original-regular-hours')
                  input.val nval
                if input.hasClass('training-hours-input')
                  nval = input.parent().attr('data-original-training-hours')
                  input.val nval

            input.blur()
        ,
        store: =>
          data           = $(@subject).data()
          row_data       = $(@subject).parent().data()
          reg_original   = parseFloat($(@subject).attr('data-original-regular-hours'))
          train_original = parseFloat($(@subject).attr('data-original-training-hours'))

          if @last_el.hasClass('training-hours-input')
            if @last_el.val().replace(' ', '') == ''
              @last_el.val(train_original);

          auxReg         = $(@subject).find('.regular-hours-input')
          auxTrain       = $(@subject).find('.training-hours-input')
          train          = train_original
          reg            = parseFloat(auxReg.val())

          auxReg.val reg.toFixed(2);

          if auxTrain.length >0
            train        = parseFloat(auxTrain.val())
            auxTrain.val train.toFixed(2);

          update         =
            regular_hours: reg, 
            training_hours: train, 
            study_id: $(@subject).attr('data-sid'), 
            user_shift_id: $(@subject).parent().attr('data-usid'), 
            ussid: $(@subject).attr('data-ussid'), 
            assigns_period_id: $('#shift-assigns').attr('data-period-id')
          service_uri    = $(@subject).parent().attr('data-ua_service_uri')

          if $(@subject).find('.regular-hours-input').length > 0
            @send_assigns service_uri, update, @subject, (response_data) =>
              response_data = JSON.parse(response_data);
              if 'success' of response_data
                if response_data.success
                  # $(@subject).attr 'data-original-regular-hours', reg
                  # $(@subject).attr 'data-original-training-hours', train

                  $(@subject).attr 'data-ussid', response_data.ussid
                  angular.element($("#shift-assigns")).scope().repopulate()
                else
                  # In this clause the HTTP result was 200 (OK) but an error occurred
                  # while trying to persist data in the payload.
              else
                # This is an HTTP failure condition where the server did not respond
                # with 200 OK.
      }
    }
    #endregion
  
  #region ITable Checkpoints
  focus: (el = null) ->
    # A method to present the inputs given each field
    @map[@map_cur]['on_activate']() if @map_cur and "on_activate" of @map[@map_cur] and el == null
    @map[@map_cur]['on_activate'](el) if @map_cur and "on_activate" of @map[@map_cur] and el != null

  blur: ->
    # A method to blur the inputs for each field
    @map[@map_cur]['on_deactivate']() if @map_cur and "on_deactivate" of @map[@map_cur]

  store: ->
    @map[@map_cur]['store'](@subject) if @map_cur and "store" of @map[@map_cur]

  respond_to: (stroke) ->
    @map[@map_cur]['on_stroke'](stroke)

  display_dialog: ->
    @map[@map_cur]['on_dblclick']()

  current_subject: (subject) ->
    @subject = subject
    @determine_actions()
    @disable_key_authority()
  #endregion
  
  #region Key Authority
  defer_to_cell: ->
    @act_as_key_authority

  enable_key_authority: ->
    @act_as_key_authority = true
    
  disable_key_authority: ->
    @act_as_key_authority = false
  #endregion

  #region AJAX Calls for Storage
  send: (service_url, update, subject) ->
    $.ajax
      url: service_url,
      data: JSON.stringify(update)
      type: 'PATCH'
      beforeSend: (xhr) =>
        xhr.setRequestHeader 'X-CSRF-Token', $('#shift-assigns').data('csrf')
      success: =>
        $(subject).data 'original', $(subject).data 'next'
        $(subject).removeData 'next'

  send_assigns: (service_url, update, subject, success_callback) ->
    $.ajax
      url: service_url,
      data: JSON.stringify(update)
      type: 'PATCH'
      beforeSend: (xhr) =>
        xhr.setRequestHeader 'X-CSRF-Token', $('#shift-assigns').data('csrf')
      success: success_callback
  #endregion

  #region Helpers
  next_value_exists: ->
    return ('next' of $(@subject).data())
  #endregion

  determine_actions: ->
    if $(@subject).hasClass('column-station')
      @map_cur = 'station'
    else if $(@subject).hasClass('column-booth')
      @map_cur = 'booth'
    else if $(@subject).hasClass('column-bg')
      @map_cur = 'break_group'
    else if $(@subject).hasClass('column-att')
      @map_cur = 'attendance'
    else if $(@subject).hasClass('column-in-out')
      @map_cur = 'in_out'
    else if $(@subject).hasClass('column-paid-hours')
      @map_cur = 'paid_hours'
    else if $(@subject).hasClass('column-project-study')
      @map_cur = 'assignments'
    return
